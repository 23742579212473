import 'slick-carousel'

jQuery(document).ready(function ($) {
	// mobile menu
	$('.menu-trigger').click(function () {
		$(this).toggleClass('active')
		$('header .wrapper').toggleClass('active')
	})

	$('.slider-hero').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		mobileFirst: true,
		prevArrow:
			'<button name="prev button" type="button" class="slick-prev"><img alt="previous" src="wp-content/themes/smk-45p/dist/img/icon-left-arrow.png"></button>',
		nextArrow:
			'<button name="next button" type="button" class="slick-next"><img alt="next" src="wp-content/themes/smk-45p/dist/img/icon-right-arrow.png"></button>',
		arrows: false,
		dots: true,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					arrows: true
				}
			}
		]
	})

	// Sticky header
	$(window).scroll(function () {
		var sticky = $('#header'),
			scroll = $(window).scrollTop()

		if (scroll >= 100) sticky.addClass('sticky')
		else sticky.removeClass('sticky')
	})
})
